export { json as yja25_0x };
const json = {
  title: "渥康 有机A2 5.0全脂奶 250mL",
  content: ``,
  imgShowList: [
    require("/src/assets/images/goods/yja25_0x.jpg"),
  ],
  goodsDetailsImgList:[
    // require("/src/assets/images/goods/youji5.0.png"),
  ]
};