export { json as qz5_0 };
const json = {
  title: "渥康5.0%全脂纯牛奶 800mL",
  content: ``,
  imgShowList: [
    require("/src/assets/images/goods/qz5.0.png"),
  ],
  goodsDetailsImgList:[
    require("/src/assets/images/goodsDetailsImg/qz5.0.jpg"),
  ]
};
