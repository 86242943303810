export { json as ywnd };
const json = {
  title: "渥康 原味奶豆 18g",
  content: ``,
  imgShowList: [
    require("/src/assets/images/goods/ywnd.png"),
  ],
  goodsDetailsImgList:[
    require("/src/assets/images/goodsDetailsImg/ywnd.jpg"),
  ]
};
