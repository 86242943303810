export { json as dz1_0 };
const json = {
  title: "渥康1.0%低脂纯牛奶 800mL",
  content: ``,
  imgShowList: [
    require("/src/assets/images/goods/dz1.0.png"),
  ],
  goodsDetailsImgList:[
    require("/src/assets/images/goodsDetailsImg/dz1.0-1.jpg"),
  ]
};
