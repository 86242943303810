export { json as  qzx1L};
const json = {
  title: "渥康 212全脂鲜牛奶 1L",
  content: ``,
  imgShowList: [
    require("/src/assets/images/goods/qzx1L.jpg"),
  ],
  goodsDetailsImgList:[
    // require("/src/assets/images/goodsDetailsImg/qklnd.jpg"),
  ]
};