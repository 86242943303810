<template>
    <div>
        <div class="container-fluid">
            <div class="row title">
                <div>渥康 {{this.spuSwitchList.title}}</div>
            </div>
        </div>
        <div class="container content-details">
            <div class="row">
                <div class="col-md-6">
                    <div class="goods-content">
                        <swiper class="swiper gallery-top" :options="swiperOption">
                            <swiperSlide class="swiper-item" v-for="(item,index) in goodsList.imgShowList" :key="index">
                                <img class="img-fluid swiper-img" :src="item" />
                            </swiperSlide>
                        </swiper>

                        <swiper class="swiper gallery-thumbs" :options="swiperOptionThumbs" ref="swiperThumbs">
                            <swiperSlide class="swiper-item" v-for="(item,index) in spuSwitchList.goodsList" :key="index" :class="item.selected">
                                <img class="img-fluid swiperbottom-img " :src="item.img" @click="switchGoods(item.goodsID,index)" />
                            </swiperSlide>
                            <!-- <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
                            <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div> -->
                        </swiper>
                    </div>

                </div>
                <div class="col-md-6 goods-details">
                    <p class="goods-details-title">{{goodsList.title}}</p>
                    <div v-html="goodsList.content"></div>
                    <div class="goodsDetailsImgList" v-if="goodsList.goodsDetailsImgList.length>0">
                        <img class="img-fluid" v-for="(item,index) in goodsList.goodsDetailsImgList" :key="index" :src="item" alt="goods-details-img">
                    </div>
                    <div class="seat" v-else>
                        敬请期待
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/swiper-bundle.css";
import { qz5_0 } from "../../assets/htmlContent/productContent/productDetails/qz5_0";
import { qz4_0 } from "../../assets/htmlContent/productContent/productDetails/qz4_0";
import { dz1_0 } from "../../assets/htmlContent/productContent/productDetails/dz1_0";
import { tz0_1 } from "../../assets/htmlContent/productContent/productDetails/tz0_1";
import { wrt5_0 } from "../../assets/htmlContent/productContent/productDetails/wrt5_0";
import { wrt1_0 } from "../../assets/htmlContent/productContent/productDetails/wrt1_0";
import { zhn } from "../../assets/htmlContent/productContent/productDetails/zhn";
import { qzwrt } from "../../assets/htmlContent/productContent/productDetails/qzwrt";
import { lanxie } from "../../assets/htmlContent/productContent/productDetails/lanxie";
import { luxie } from "../../assets/htmlContent/productContent/productDetails/luxie";
import { ywnd } from "../../assets/htmlContent/productContent/productDetails/ywnd";
import { qklnd } from "../../assets/htmlContent/productContent/productDetails/qklnd";
import { cnd } from "../../assets/htmlContent/productContent/productDetails/cnd";
import { tz01x } from "../../assets/htmlContent/productContent/productDetails/tz01x";
import { tz50x } from "../../assets/htmlContent/productContent/productDetails/tz50x";
import { qzx35 } from "../../assets/htmlContent/productContent/productDetails/qzx35";
import { qzx1L } from "../../assets/htmlContent/productContent/productDetails/qzx1L";
import { baiqzx35 } from "../../assets/htmlContent/productContent/productDetails/baiqzx35";
import { lanqzx35 } from "../../assets/htmlContent/productContent/productDetails/lanqzx35";
import { yj5_0 } from "../../assets/htmlContent/productContent/productDetails/yj5_0";
import { yj1_0 } from "../../assets/htmlContent/productContent/productDetails/yj1_0";
import { yja25_0 } from "../../assets/htmlContent/productContent/productDetails/yja25_0";
import { yja25_0x } from "../../assets/htmlContent/productContent/productDetails/yja25_0x";
import { yja21_0 } from "../../assets/htmlContent/productContent/productDetails/yja21_0";
export default {
    data() {
        return {
            spuData: {},
            spuSwitchList: [],
            goodsList: {},
            swiperOption: {
                slidesPerView: 1,
                grabCursor: true,
                // loop: true,
                //滑动速度
                speed: 1000,
            },
            swiperOptionThumbs: {
                slidesPerView: 5,
                grabCursor: true,
                // loop: true,
                //滑动速度
                speed: 1000,
                slideToClickedSlide: true,
            },
        };
    },
    components: {
        swiper,
        swiperSlide,
    },
    created() {
        let productObj = JSON.parse(localStorage.getItem("productObj"));
        this.spuData = productObj.spuData;
        this.spuSwitchList = productObj.spuSwitchList;
        for (let i in this.spuSwitchList.goodsList) {
            if (
                this.spuSwitchList.goodsList[i].goodsID == this.spuData.goodsID
            ) {
                this.spuSwitchList.goodsList[i].selected = "switch-selected";
                //goodsid 请求数据
                if (this.spuSwitchList.goodsList[i].goodsID == "qz50") {
                    this.goodsList = qz5_0;
                } else if (this.spuSwitchList.goodsList[i].goodsID == "qz40") {
                    this.goodsList = qz4_0;
                } else if (this.spuSwitchList.goodsList[i].goodsID == "dz10") {
                    this.goodsList = dz1_0;
                } else if (this.spuSwitchList.goodsList[i].goodsID == "tz01") {
                    this.goodsList = tz0_1;
                } else if (this.spuSwitchList.goodsList[i].goodsID == "wrt50") {
                    this.goodsList = wrt5_0;
                } else if (this.spuSwitchList.goodsList[i].goodsID == "wrt10") {
                    this.goodsList = wrt1_0;
                } else if (this.spuSwitchList.goodsList[i].goodsID == "zhn") {
                  this.goodsList = zhn;
                } else if (this.spuSwitchList.goodsList[i].goodsID == "qzwrt") {
                    this.goodsList = qzwrt;
                } else if (this.spuSwitchList.goodsList[i].goodsID == "lanxie") {
                    this.goodsList = lanxie;
                } else if (this.spuSwitchList.goodsList[i].goodsID == "luxie") {
                    this.goodsList = luxie;
                } else if (this.spuSwitchList.goodsList[i].goodsID == "ywnd") {
                    this.goodsList = ywnd;
                } else if (this.spuSwitchList.goodsList[i].goodsID == "qklnd") {
                    this.goodsList = qklnd;
                } else if (this.spuSwitchList.goodsList[i].goodsID == "cnd") {
                  this.goodsList = cnd;
                } else if (this.spuSwitchList.goodsList[i].goodsID == "tz01x") {
                    this.goodsList = tz01x;
                } else if (this.spuSwitchList.goodsList[i].goodsID == "tz50x") {
                    this.goodsList = tz50x;
                } else if (this.spuSwitchList.goodsList[i].goodsID == "qzx35") {
                    this.goodsList = qzx35;
                } else if (this.spuSwitchList.goodsList[i].goodsID == "qzx1L") {
                    this.goodsList = qzx1L;
                } else if (
                    this.spuSwitchList.goodsList[i].goodsID == "baiqzx35"
                ) {
                    this.goodsList = baiqzx35;
                } else if (
                    this.spuSwitchList.goodsList[i].goodsID == "lanqzx35"
                ) {
                    this.goodsList = lanqzx35;
                } else if (this.spuSwitchList.goodsList[i].goodsID == "yj5_0") {
                    this.goodsList = yj5_0;
                } else if (this.spuSwitchList.goodsList[i].goodsID == "yj1_0") {
                    this.goodsList = yj1_0;
                } else if (this.spuSwitchList.goodsList[i].goodsID == "yja25_0") {
                    this.goodsList = yja25_0;
                } else if (this.spuSwitchList.goodsList[i].goodsID == "yja25_0x") {
                  this.goodsList = yja25_0x;
                } else if (
                    this.spuSwitchList.goodsList[i].goodsID == "yja21_0"
                ) {
                    this.goodsList = yja21_0;
                }
            }
        }
    },
    mounted() {
        let index = this.spuSwitchList.goodsList.findIndex(
            (arr) => arr.goodsID == this.spuData.goodsID
        );
        this.$refs.swiperThumbs.swiper.slideTo(index, 2000, false);
    },
    methods: {
        switchGoods(goodsID, index) {
            console.log(index);

            document
                .getElementById("scrolldiv")
                .scrollTo({ top: 0, behavior: "smooth" });
            for (let i in this.spuSwitchList.goodsList) {
                this.spuSwitchList.goodsList[i].selected = "";
                if (this.spuSwitchList.goodsList[i].goodsID == goodsID) {
                    this.spuSwitchList.goodsList[i].selected =
                        "switch-selected";
                    //goodsid 请求数据
                    if (goodsID == "qz50") {
                        this.goodsList = qz5_0;
                    } else if (goodsID == "qz40") {
                        this.goodsList = qz4_0;
                    } else if (goodsID == "dz10") {
                        this.goodsList = dz1_0;
                    } else if (goodsID == "tz01") {
                        this.goodsList = tz0_1;
                    } else if (goodsID == "wrt50") {
                        this.goodsList = wrt5_0;
                    } else if (goodsID == "wrt10") {
                        this.goodsList = wrt1_0;
                    } else if (goodsID == "zhn") {
                      this.goodsList = zhn;
                    } else if (goodsID == "qzwrt") {
                        this.goodsList = qzwrt;
                    } else if (goodsID == "lanxie") {
                        this.goodsList = lanxie;
                    } else if (goodsID == "luxie") {
                        this.goodsList = luxie;
                    } else if (goodsID == "ywnd") {
                        this.goodsList = ywnd;
                    } else if (goodsID == "qklnd") {
                        this.goodsList = qklnd;
                    } else if (goodsID == "cnd") {
                      this.goodsList = cnd;
                    } else if (goodsID == "tz01x") {
                        this.goodsList = tz01x;
                    } else if (goodsID == "tz50x") {
                        this.goodsList = tz50x;
                    } else if (goodsID == "qzx35") {
                        this.goodsList = qzx35;
                    } else if (goodsID == "qzx1L") {
                        this.goodsList = qzx1L;
                    } else if (goodsID == "baiqzx35") {
                        this.goodsList = baiqzx35;
                    } else if (goodsID == "lanqzx35") {
                        this.goodsList = lanqzx35;
                    } else if (goodsID == "yj1_0") {
                        this.goodsList = yj1_0;
                    } else if (goodsID == "yj5_0") {
                        this.goodsList = yj5_0;
                    } else if (goodsID == "yja25_0") {
                        this.goodsList = yja25_0;
                    }else if (goodsID == "yja25_0x") {
                      this.goodsList = yja25_0x;
                    } else if (goodsID == "yja21_0") {
                        this.goodsList = yja21_0;
                    }
                }
            }
        },
    },
};
</script>

<style scoped>
.insets {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.swiper-button-prev {
    color: rgba(196, 196, 196, 1);
}
.swiper-button-next {
    color: rgba(196, 196, 196, 1);
}
.swiper-button-prev,
.swiper-button-next {
    /* width: 1x;
    height: 1px; */
    margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    /* color: var(--swiper-navigation-color, var(--swiper-theme-color)); */
}
@media (min-width: 975px) {
    .title {
        height: 60px;
        width: 100%;
        font-size: 32px;
        font-weight: bold;
        --bs-bg-opacity: 1;
        background-color: white;
        border-bottom: 1px solid rgba(218, 218, 218, 1);
        position: fixed;
        z-index: 100;
    }
    .title > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
    .content-details {
        padding-top: 50px;
    }
    .switch-selected {
        margin-top: 5px;
        border-bottom: 2px solid rgba(51, 51, 51, 1);
    }
    .goods-details {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
    .goods-details > p {
        text-align: left;
        font-size: 42px;
        margin: 50px 0px;
    }
    .goods-details > div:nth-child(1) {
        font-size: 24px;
    }

    .goodsDetailsImgList {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .goodsDetailsImgList > img {
        width: 100%;
        height: auto;
    }
    .goods-content {
        /*需要宽度*/
        width: 500px;
        background-color: white;
        position: fixed;
        /* left: 15%; */
        bottom: 30px;
    }
    .swiper-img {
        padding: 50px 0px;
    }
    .swiperbottom-img {
        padding: 10px 0px;
    }
    .insets > img {
        width: 100px;
        height: 100px;
        margin: 0px 10px;
    }
    .seat {
        width: 100%;
        height: 550px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        font-weight: 500;
        color: #999999;
        /* padding: 500px 0px; */
    }
}
@media (max-width: 991px) {
    .title {
        height: 60px;
        width: 100%;
        font-size: 25px;
        font-weight: bold;
        --bs-bg-opacity: 1;
        background-color: white;
        border-bottom: 1px solid rgba(218, 218, 218, 1);
        position: fixed;
    }
    .title > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
    .content-details {
        padding-top: 50px;
    }
    .insets > img {
        width: 60px;
        height: 60px;
        margin: 0px 10px;
    }
    .switch-selected {
        margin-top: 5px;
        border-bottom: 2px solid rgba(51, 51, 51, 1);
    }
    .goods-details {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
    .goods-details > p {
        text-align: left;
        font-size: 30px;
        margin: 50px 0px;
    }
    .goods-details > div:nth-child(1) {
        font-size: 18px;
    }
    .goodsDetailsImgList > img {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
        height: auto;
    }
    .goods-content {
        width: 300px;
        height: auto;
        position: fixed;
    }
    .swiper-img {
        padding: 50px 0px;
    }
}
@media (max-width: 767px) {
    .title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        height: 60px;
        width: 100%;
        font-size: 16px;
        font-weight: bold;
        --bs-bg-opacity: 1;
        background-color: white;
        border-bottom: 1px solid rgba(218, 218, 218, 1);
        position: fixed;
        z-index: 100;
        text-align: left;
    }
    .content-details {
        padding-top: 50px;
    }
    .insets > img {
        width: 75px;
        height: 75px;
        margin: 0px 10px;
    }
    .switch-selected {
        margin-top: 5px;
        border-bottom: 2px solid rgba(51, 51, 51, 1);
    }
    .goods-details {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
    .goods-details > p {
        text-align: left;
        font-size: 25px;
        margin: 25px 0px;
    }
    .goods-details > div:nth-child(1) {
        font-size: 14px;
    }
    .goodsDetailsImgList > img {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
        height: auto;
    }
    .goods-content {
        width: 300px;
        height: auto;
        position: initial;
        margin-left: 30px;
    }
    .swiper-img {
        padding: 50px;
    }
    .swiperbottom-img {
        padding: 10px 0px;
    }
}
</style>