export { json as qklnd };
const json = {
  title: "渥康 巧克力粒奶豆 18g",
  content: ``,
  imgShowList: [
    require("/src/assets/images/goods/qklnd.png"),
  ],
  goodsDetailsImgList:[
    require("/src/assets/images/goodsDetailsImg/qklnd.jpg"),
  ]
};
