export { json as yj5_0 };
const json = {
  title: "渥康 5.0%全脂无乳糖奶 800mL",
  content: ``,
  imgShowList: [
    require("/src/assets/images/goods/youji5.0.png"),
  ],
  goodsDetailsImgList:[
    // require("/src/assets/images/goods/youji5.0.png"),
  ]
};