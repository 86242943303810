export { json as lanxie };
const json = {
  title: "渥康 全脂牛乳粉 1kg",
  content: ``,
  imgShowList: [
    require("/src/assets/images/goods/lanxie.png"),
  ],
  goodsDetailsImgList:[
    require("/src/assets/images/goodsDetailsImg/lanxie_01.png"),
    require("/src/assets/images/goodsDetailsImg/lanxie_02.png"),
    require("/src/assets/images/goodsDetailsImg/lanxie_03.png"),
    require("/src/assets/images/goodsDetailsImg/lanxie_04.png"),
    require("/src/assets/images/goodsDetailsImg/lanxie_05.png"),
    require("/src/assets/images/goodsDetailsImg/lanxie_06.png"),
    require("/src/assets/images/goodsDetailsImg/lanxie_07.png"),
    require("/src/assets/images/goodsDetailsImg/lanxie_08.png"),
    require("/src/assets/images/goodsDetailsImg/lanxie_09.png"),
    require("/src/assets/images/goodsDetailsImg/lanxie_10.png"),
    require("/src/assets/images/goodsDetailsImg/lanxie_11.png"),
  ]
};
