export { json as  qzx35};
const json = {
  title: "渥康 3.5%全脂鲜牛奶 225mL",
  content: ``,
  imgShowList: [
    require("/src/assets/images/goods/3.5qzx.jpg"),
  ],
  goodsDetailsImgList:[
    // require("/src/assets/images/goodsDetailsImg/qklnd.jpg"),
  ]
};