export { json as luxie };
const json = {
  title: "渥康 脱脂牛乳粉 1kg",
  content: ``,
  imgShowList: [
    require("/src/assets/images/goods/luxie.png"),
  ],
  goodsDetailsImgList:[
    require("/src/assets/images/goodsDetailsImg/luxie_01.png"),
    require("/src/assets/images/goodsDetailsImg/luxie_02.png"),
    require("/src/assets/images/goodsDetailsImg/luxie_03.png"),
    require("/src/assets/images/goodsDetailsImg/luxie_04.png"),
    require("/src/assets/images/goodsDetailsImg/luxie_05.png"),
    require("/src/assets/images/goodsDetailsImg/luxie_06.png"),
    require("/src/assets/images/goodsDetailsImg/luxie_07.png"),
  ]
};
