export { json as  lanqzx35};
const json = {
  title: "渥康 极速蓝版3.5%全脂鲜牛奶 2L",
  content: ``,
  imgShowList: [
    require("/src/assets/images/goods/lanqzx3.5.jpg"),
  ],
  goodsDetailsImgList:[
    // require("/src/assets/images/goodsDetailsImg/qklnd.jpg"),
  ]
};