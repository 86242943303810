export { json as  tz50x};
const json = {
  title: "渥康 5.0%全脂纯牛奶 250mL",
  content: ``,
  imgShowList: [
    require("/src/assets/images/goods/5.0x.png"),
  ],
  goodsDetailsImgList:[
    require("/src/assets/images/goodsDetailsImg/tz50x.jpg"),
  ]
};