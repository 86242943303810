export { json as yja25_0 };
const json = {
  title: "渥康 有机A2 5.0全脂奶 800mL",
  content: ``,
  imgShowList: [
    require("/src/assets/images/goods/yja2_5.0.jpg"),
  ],
  goodsDetailsImgList:[
    // require("/src/assets/images/goods/youji5.0.png"),
  ]
};