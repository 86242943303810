export { json as cnd };
const json = {
  title: "渥康 纯奶豆 18g",
  content: ``,
  imgShowList: [
    require("/src/assets/images/goods/cnd.jpg"),
  ],
  goodsDetailsImgList:[
    //require("/src/assets/images/goodsDetailsImg/cnd.jpg"),
  ]
};
