export { json as tz0_1 };
const json = {
  title: "渥康0.1%脱脂纯牛奶 800mL",
  content: ``,
  imgShowList: [
    require("/src/assets/images/goods/tz0.1.png"),
  ],
  goodsDetailsImgList:[
    require("/src/assets/images/goodsDetailsImg/tz0.1-1.jpg"),
  ]
};
