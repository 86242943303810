export { json as wrt1_0 };
const json = {
  title: "渥康 1.0%低脂无乳糖奶 800mL",
  content: ``,
  imgShowList: [
    require("/src/assets/images/goods/1.0wrt.png"),
  ],
  goodsDetailsImgList:[
    require("/src/assets/images/goodsDetailsImg/wrt1.0-1.jpg"),
  ]
};