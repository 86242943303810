export { json as zhn };
const json = {
  title: "渥康 纸盒奶 250ml",
  content: ``,
  imgShowList: [
    require("/src/assets/images/goods/zhn.jpg"),
  ],
  goodsDetailsImgList:[
    //require("/src/assets/images/goodsDetailsImg/cnd.jpg"),
  ]
};
