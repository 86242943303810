export { json as  tz01x};
const json = {
  title: "渥康 0.1%脱脂纯牛奶 250mL",
  content: ``,
  imgShowList: [
    require("/src/assets/images/goods/0.1x.png"),
  ],
  goodsDetailsImgList:[
    // require("/src/assets/images/goodsDetailsImg/qklnd.jpg"),
  ]
};
