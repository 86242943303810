export { json as qzwrt };
const json = {
  title: "渥康 全脂巧克力无乳糖奶 250mL",
  content: ``,
  imgShowList: [
    require("/src/assets/images/goods/qzqkl.png"),
  ],
  goodsDetailsImgList:[
    require("/src/assets/images/goodsDetailsImg/qzwrt.jpg"),
  ]
};
