export { json as qz4_0 };
const json = {
  title: "渥康4.0%全脂纯牛奶 800mL",
  content: ``,
  imgShowList: [
    require("/src/assets/images/goods/4.0.png"),
  ],
  goodsDetailsImgList:[
    require("/src/assets/images/goodsDetailsImg/qz4.0-1.jpg"),
  ]
};